'use client';
import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/navigation';
import utilsService from '../helpers/utilsService';

// Define the shape of the context
interface PostMessageContextValue {
    isAllowedNotifications: boolean | null;
    sendPermissionRequest: (isAllowed: boolean) => Promise<boolean>;
}

// Create context
const PostMessageContext = createContext<PostMessageContextValue | undefined>(undefined);

// Provider component
export const PostMessageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    let permissionResolver: ((value: boolean) => void) | null = null;
    const [isAllowedNotifications, setIsAllowedNotifications] = useState<boolean | null>(null);
    // Handler for postMessage
    const handlePostMessage = async (event: any) => {
        try {
            const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
            if (data?.IS_ALLOW_NOTIFICATIONS_WEBVIEW !== undefined) {
                setIsAllowedNotifications(data.IS_ALLOW_NOTIFICATIONS_WEBVIEW);
                if (permissionResolver) {
                    permissionResolver(data.IS_ALLOW_NOTIFICATIONS_WEBVIEW);
                    permissionResolver = null; // Reset resolver
                }
            }
            if (data?.MESSAGE_TYPE === 'notification') {
                const link = data.link;
                gotoLink(link);
            }
        } catch (error) {
            console.error('Error handling postMessage:', error);
        }
    };

    const sendPermissionRequest = useCallback((): Promise<boolean> => {
        return new Promise((resolve) => {
            if (window.ReactNativeWebView) {
                permissionResolver = resolve;
                window.ReactNativeWebView.postMessage('IS_ALLOW_NOTIFICATIONS_WEBVIEW');
            } else {
                resolve(false);
            }
        });
    }, []);

    function gotoLink(url: string) {
        if (url && utilsService.isUrl(url)) {
            const parsedUrl = new URL(url);
            const domain = utilsService.normalizeDomain(parsedUrl.hostname);
            const currentDomain = utilsService.getAppDomain();
            // console.log('domain', domain, 'currentDomain', currentDomain);
            if (domain == currentDomain) {
                router.push(parsedUrl.pathname);
            } else {
                return;
                window.open(url, '_blank');
            }
        }
    }

    // Add listener for postMessage
    useEffect(() => {
        if (window.ReactNativeWebView) {
            window.addEventListener('message', handlePostMessage);
            document.addEventListener('message', handlePostMessage); // Android
            window.ReactNativeWebView.postMessage('IS_LISTENING_READY');
        }

        return () => {
            if (window.ReactNativeWebView) {
                window.removeEventListener('message', handlePostMessage);
                document.removeEventListener('message', handlePostMessage); // Android
            }
            // }
        };
    }, []);

    return <PostMessageContext.Provider value={{ isAllowedNotifications, sendPermissionRequest }}>{children}</PostMessageContext.Provider>;
};

// Hook for consuming the context
export const usePostMessage = () => {
    const context = useContext(PostMessageContext);
    if (!context) {
        throw new Error('usePostMessage must be used within a PostMessageProvider');
    }
    return context;
};
