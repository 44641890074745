'use client';
import React, { createContext, useContext, useEffect } from 'react';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { clearAuthIgnoreCookie, clearUserCookies, deleteExpiredToken } from '../helpers/cookiesMethod';
import { usePathname, useRouter } from 'next/navigation';
import { useUser } from '../hooks/useUser';

// Define the app version
const APP_VERSION = process.env.APP_VERSION;

// Context definition
interface AppVersionContextProps {
    isAppVersionSet: boolean;
}

const AppVersionContext = createContext<AppVersionContextProps | undefined>(undefined);

// Context provider
export const AppVersionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAppVersionSet, setIsAppVersionSet] = React.useState(false);
    const { updateDataFromCookiesForProvider } = useUser();
    const pathName = usePathname();
    useEffect(() => {
        const existingAppVersion = getCookie('APP_VERSION') as string | undefined;
        if (existingAppVersion !== APP_VERSION || !existingAppVersion) {
            deleteExpiredToken();
            deleteCookie('access_token');
            deleteCookie('badgeChallengeAlertClosed');
            clearAuthIgnoreCookie('auth_ignore');
            clearUserCookies();
            updateDataFromCookiesForProvider?.();
            if (existingAppVersion && existingAppVersion !== APP_VERSION) {
                deleteCookie('APP_VERSION', { path: '/' });
            }
            setCookie('APP_VERSION', APP_VERSION, { path: '/' });
        } else {
            setIsAppVersionSet(true);
        }
    }, []);

    return <AppVersionContext.Provider value={{ isAppVersionSet }}>{children}</AppVersionContext.Provider>;
};

// Custom hook to use the AppVersionContext
export const useAppVersion = (): AppVersionContextProps => {
    const context = useContext(AppVersionContext);
    if (!context) {
        throw new Error('useAppVersion must be used within an AppVersionProvider');
    }
    return context;
};
